import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';

import type { NotificationItem } from './types';

const TTL = 5000; // Notification default auto closing delay

@Store
export class NotificationStore extends BaseStore {
  @Field
  private notifications: NotificationItem[];

  public spawnNotification(notification: NotificationItem) {
    if ((this.notifications || []).some((n) => n.message === notification.message)) {
      return;
    }

    this.notifications = [...(this.notifications || []), notification];

    if (!notification.hasNoTimeout) {
      setTimeout(() => {
        this.removeNotification();
      }, notification.timeToLive || TTL);
    }
  }

  public removeNotification(index?: number) {
    this.notifications =
      index !== undefined ? this.notifications.filter((_, i) => i !== index) : this.notifications.slice(0, -1);
  }

  public get allNotifications() {
    return this.notifications || [];
  }
}
